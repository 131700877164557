import { FormHelperText, LinearProgress } from '@material-ui/core';
import React from 'react';
import { Page } from '../../components/Page';
import { Button, Link, TextField } from '../../components/styled';
import { useAwsApi } from '../../hooks';

export function ChangePasswordForm() {
  const [username, setUsername] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [message, setInfo] = React.useState('');
  const { changePassword } = useAwsApi();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setError('');
    setInfo('');
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewPassword(event.target.value);
    setError('');
    setInfo('');
  };

  const handleConfirmNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmNewPassword(event.target.value);

    if (event.target.value !== newPassword) {
      setError('New password does not match confirmation');
    } else {
      setError('');
    }
    setInfo('');
  };

  const handleOldPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOldPassword(event.target.value);
    setError('');
    setInfo('');
  };

  const handleChangePassword = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    changePassword({ username, oldPassword, newPassword })
      .then((res) => {
        setInfo(`You password has been reset, please check your inbox.`);
        resetAllFields();
        setError('');
        setLoading(false);
      })
      .catch((err) => {
        setError('Unable to change password');
        setLoading(false);
        return;
      });
  };

  const resetAllFields = () => {
    setNewPassword('');
    setOldPassword('');
    setConfirmNewPassword('');
    setUsername('');
  };

  return (
    <Page title="Change Password">
      {isLoading && <LinearProgress />}
      {message && <FormHelperText>{message}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
      <TextField
        label="Email"
        onChange={handleUsernameChange}
        value={username}
        autoFocus
      />
      <TextField
        label="Old Password"
        onChange={handleOldPasswordChange}
        type="password"
        value={oldPassword}
      />
      <TextField
        label="New Password"
        onChange={handleNewPasswordChange}
        type="password"
        value={newPassword}
      />
      <TextField
        label="Confirm New Password"
        onChange={handleConfirmNewPasswordChange}
        type="password"
        value={confirmNewPassword}
      />
      <Button
        disabled={
          !username ||
          !oldPassword ||
          !newPassword ||
          confirmNewPassword !== newPassword ||
          isLoading
        }
        onClick={handleChangePassword}
      >
        Reset password
      </Button>
      <Link to="/">Cancel</Link>
    </Page>
  );
}
