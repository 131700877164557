import { TokenSession } from '@jupiterone/login-shared';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export function cognitoSessionToTokenSession(
  cognitoSession: CognitoUserSession,
): TokenSession {
  return {
    access_token: cognitoSession.getAccessToken().getJwtToken(),
    id_token: cognitoSession.getIdToken().getJwtToken(),
    refresh_token: cognitoSession.getRefreshToken().getToken(),
  };
}
