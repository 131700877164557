import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useAccounts } from '~/src/hooks/useAccounts';
import { useInvitations } from '~/src/hooks/useInvitations';
import { Page } from '../../components/Page';
import { Invitations } from './Invitations';
import { CreateAccount } from './NoAccess';
export function AccountlessPage() {
  const {
    invitations,
    loading: loadingInvitations,
    getInvitations,
  } = useInvitations();
  const { accounts, getAccounts, loading: loadingAccounts } = useAccounts();
  const hasInvitations = !loadingInvitations && invitations.length > 0;

  const loading = loadingInvitations || loadingAccounts;

  useEffect(() => {
    getAccounts();
  }, [hasInvitations]);

  useEffect(() => {
    if (accounts.length > 0) {
      document.location = '/account-selector?destination=/welcome/paths';
    }
  }, [accounts]);

  useEffect(() => {
    void getInvitations();
  }, []);

  const title = hasInvitations ? 'Invitations' : 'Welcome!';

  return (
    <Page title={title} centerTitle={true} showRightColumn={false}>
      {!loading && hasInvitations && (
        <Invitations
          invitations={invitations}
          getInvitations={getInvitations}
        />
      )}
      {!loading && !hasInvitations && <CreateAccount />}
      {loading && <CircularProgress />}
    </Page>
  );
}
