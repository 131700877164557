import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Page } from '../../components/Page';
import { Button } from '../../components/styled';
import { useAccountSelectorLogic } from './useAccountSelectorLogic';

export const useStyles = makeStyles((theme) => ({
  centeredText: {
    marginTop: 15,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
  },
  center: {
    margin: 0,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'contents',
  },
}));

export function AccountSelector() {
  const classes = useStyles();
  const { accounts } = useAccountSelectorLogic();

  const login = (account: { accountId: string }) => {
    const { accountId } = account;

    const redirect = `/account/${accountId}${window.location.search}`;
    location.href = redirect;
  };

  const logout = () => {
    //Set subdomain to nothing to pass through to '/'
    location.href = '/auth/logout';
  };

  return (
    <Page title="Select an account" centerTitle={true} showRightColumn={false}>
      <Box pt={1} maxWidth={200} className={classes.center} maxHeight={50}>
        {accounts.map((account) => (
          <Button
            data-testid={`account-selector-account-id-${account.accountId}`}
            onClick={() => login(account)}
          >
            <span>
              <span>{account.accountName}</span>
            </span>
          </Button>
        ))}
        <Divider orientation="horizontal" className={classes.centeredText} />
        <Typography className={classes.centeredText}>
          <Button data-testid={`account-selector-cancel`} onClick={logout}>
            Cancel
          </Button>
        </Typography>
      </Box>
    </Page>
  );
}
