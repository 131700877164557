import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useInvitations } from '~/src/hooks/useInvitations';
import { Button } from '../../../components/styled';

export const useStyles = makeStyles((theme) => ({
  shadow: {
    boxShadow: theme.shadows[5],
  },
  centeredText: {
    marginTop: 15,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'left',
  },
}));

export function Invitations(options: {
  invitations: {
    invitationId: string;
    groupName: string;
    accountName: string;
    invitationInitiatorEmail?: string | null;
  }[];
  getInvitations: () => void;
}) {
  const { invitations, getInvitations } = options;
  const classes = useStyles();
  const [error, setError] = useState<string>();

  const {
    updateInvitation,
    updated,
    error: ApiError,
    loading,
  } = useInvitations();

  useEffect(() => {
    if (ApiError) {
      setError(ApiError);
    }
  }, [ApiError]);

  const logout = () => {
    location.href = '/auth/logout';
  };

  const handleAcceptInvitation = (invitationId: string) => {
    updateInvitation(invitationId, 'ACCEPTED')
      .then(() => getInvitations())
      .catch((e) => setError(e.message));
  };

  const handleRejectInvitation = (invitationId: string) => {
    updateInvitation(invitationId, 'REJECTED')
      .then(() => getInvitations())
      .catch((e) => setError(e.message));
  };

  return (
    <Box pt={1} marginTop="24px">
      <>
        {invitations.map((invitation) => (
          <Box marginBottom="16px">
            <Card variant="outlined" className={classes.shadow}>
              <CardHeader title={invitation.groupName} />

              <CardContent>
                You have been invited to the {invitation.groupName} in{' '}
                {invitation.accountName}.
              </CardContent>
              <CardActions>
                <Button
                  onClick={() =>
                    handleAcceptInvitation(invitation.invitationId)
                  }
                >
                  Accept
                </Button>
                <Button
                  onClick={() =>
                    handleRejectInvitation(invitation.invitationId)
                  }
                >
                  Reject
                </Button>
              </CardActions>
            </Card>
          </Box>
        ))}
      </>

      {updated && (
        <FormHelperText error={false}>
          Invitation {updated.status}
        </FormHelperText>
      )}
      {error && <FormHelperText error>{error}</FormHelperText>}
      {loading && <LinearProgress />}
      <Divider orientation="horizontal" />
      <Typography className={classes.centeredText}>
        <Button data-testid={`no-access-cancel`} onClick={logout}>
          Cancel
        </Button>
      </Typography>
    </Box>
  );
}
