import {
  Container,
  Link,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React from 'react';
import GitHubIcon from '../../../public/icons/github.png';
import LinkedInIcon from '../../../public/icons/linkedIn.png';
import SlackIcon from '../../../public/icons/slack.png';
import TwitterIcon from '../../../public/icons/twitter.png';
import { LinkImageButton } from './LinkImageButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    position: 'absolute',
    bottom: 16,
  },
  text: {
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    fontSize: 14,
  },
}));

// Changes the url of the image to point BUILD_ID key path
const githubIconUrl = GitHubIcon.replace('BUILD_ID', window.buildId);
const linkedInIconUrl = LinkedInIcon.replace('BUILD_ID', window.buildId);
const slackIconUrl = SlackIcon.replace('BUILD_ID', window.buildId);
const twitterIconUrl = TwitterIcon.replace('BUILD_ID', window.buildId);

export default function Footer() {
  const classes = useStyles();
  const iconWidth = '40%';

  return (
    <Container component="main" className={classes.root}>
      <Toolbar className={classes.container}>
        <LinkImageButton
          href="https://github.com/JupiterOne"
          imageUrl={githubIconUrl}
          width={iconWidth}
        />
        <LinkImageButton
          href="https://www.linkedin.com/company/jupiterone/"
          imageUrl={linkedInIconUrl}
          width={iconWidth}
        />
        <LinkImageButton
          href="https://twitter.com/jupiterone"
          imageUrl={twitterIconUrl}
          width={iconWidth}
        />
        <LinkImageButton
          href="https://app.slack.com/client/TJBH82C56/CJ03CAL11"
          imageUrl={slackIconUrl}
          width={iconWidth}
        />
        <Link href="https://jupiterone.com/terms/" className={classes.text}>
          <Typography className={classes.text} variant="body1" color="inherit">
            Terms
          </Typography>
        </Link>
        <Typography className={classes.text} variant="body1" color="inherit">
          —
        </Typography>
        <Link
          href="https://jupiterone.com/privacy-policy/"
          className={classes.text}
        >
          <Typography className={classes.text} variant="body1" color="inherit">
            Privacy Policy
          </Typography>
        </Link>
        <Typography className={classes.text} variant="body1" color="inherit">
          —
        </Typography>
        <Link
          href="https://jupiterone.com/trust-and-transparency/"
          className={classes.text}
        >
          <Typography className={classes.text} variant="body1" color="inherit">
            Trust and Transparency
          </Typography>
        </Link>
        <Typography className={classes.text} variant="body1" color="inherit">
          —
        </Typography>
        <Link href="https://jupiterone.com/sbom/" className={classes.text}>
          <Typography className={classes.text} variant="body1" color="inherit">
            Software Bill of Materials
          </Typography>
        </Link>
      </Toolbar>
    </Container>
  );
}
