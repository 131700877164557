import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  makeStyles,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import React from 'react';
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: 24,
    marginBottom: 0,
  },
  button: {
    marginTop: 20,
  },
  link: {
    marginTop: 20,
    fontSize: 16,
    color: '#6f6f6f',
  },
  form: {
    minWidth: '100%',
  },
}));

export const TextField = (props: MuiTextFieldProps) => {
  const classes = useStyles();
  return (
    <MuiTextField
      {...props}
      className={classes.textField}
      margin="normal"
      fullWidth
    />
  );
};

export const Button = (props: MuiButtonProps) => {
  const classes = useStyles();
  return (
    <MuiButton
      {...props}
      color="primary"
      variant="contained"
      type="submit"
      className={classes.button}
      fullWidth
    />
  );
};

export const Link = (props: RRLinkProps) => {
  const classes = useStyles();
  return <RRLink {...props} className={classes.link} />;
};

export const Form = (props: any) => {
  const classes = useStyles();
  return <form {...props} className={classes.form} />;
};
