import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import Wordmark from '../../../public/JupiterOneWordmark.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(5),
    paddingLeft: 0,
    paddingRight: 0,
    width: '340px',
    zIndex: 100,
    position: 'relative',
  },
}));

export default function Logo() {
  const classes = useStyles();

  let logoUrl;
  if (window.location.origin.endsWith('cisco.com')) {
    logoUrl = `${window.appBaseUrl}/static/jupiterone/images/Cisco_ASM_ProductHeader.png`;
  } else {
    // Changes the url of the image to point to the CDN url
    logoUrl = Wordmark.replace('BUILD_ID', window.buildId);
  }

  return (
    <Container component="main" className={classes.root} maxWidth="xs">
      <img src={logoUrl} width="100%" />
    </Container>
  );
}
