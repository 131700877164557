import { FormHelperText, LinearProgress } from '@material-ui/core';
import React from 'react';
import { Page } from '../../components/Page';
import { Button, Form, Link, TextField } from '../../components/styled';
import { useLoginApi } from '../../hooks';

export function ForgotSSOForm() {
  const [email, setEmail] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [message, setInfo] = React.useState('');
  const { forgotSso } = useLoginApi();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError('');
    setInfo('');
  };

  const handleForgotSSO = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();
    setError('');
    setInfo('');

    try {
      await forgotSso(email);
      setInfo(`Links have been sent to your email`);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  };

  const wrapFormEvent = (func: (event: React.FormEvent) => Promise<void>) => {
    return (event: React.FormEvent) => {
      func(event).catch((err) => {
        setError(err.message);
      });
    };
  };

  return (
    <Page title="Forgot SSO">
      {isLoading && <LinearProgress />}
      {message && <FormHelperText>{message}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
      <Form onSubmit={wrapFormEvent(handleForgotSSO)}>
        <TextField
          label="Email"
          onChange={handleEmailChange}
          value={email}
          autoFocus
        />
        <Button disabled={!email || isLoading}>Get links</Button>
      </Form>
      <Link to="/">Back</Link>
    </Page>
  );
}
