import { PersistedSession } from '@jupiterone/login-shared';

export function useLoginApi() {
  return {
    logout: async (): Promise<void> => {
      const response = await fetch('/auth/logout');
      if (response.status < 200 || response.status > 300) {
        throw new Error('Logout failed.');
      }
    },

    setSession: async (body: PersistedSession) => {
      const response = await fetch('/auth/session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.status >= 400)
        throw new Error('Failure to get authorization code');

      return;
    },

    forgotSso: async (email: string) => {
      const response = await fetch(`/auth/forgotSSO?email=${email}`, {
        method: 'POST',
      });

      if (response.status >= 400) throw new Error('Failure to get login links');
    },
  };
}
