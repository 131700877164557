import {
  Box,
  FormHelperText,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import { cognitoSessionToTokenSession } from '~/src/utils/cognitoUtils';
import { useAwsApi, useLoginApi } from '../../../hooks';
import { Button, Form, Link, TextField } from '../../../components/styled';

export function EmailLogin() {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { loginWithCredentials } = useAwsApi();
  const { setSession } = useLoginApi();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setError('');
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError('');
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const session = await loginWithCredentials(username, password);

      const tokenSession = cognitoSessionToTokenSession(session);

      await setSession({
        session: tokenSession,
      });

      document.location.assign(`/account-selector${window.location.search}`);
    } catch (err) {
      setError('Unable to login. Try again or contact support.');
    }

    setLoading(false);
  };

  const wrapHandleFormSubmit = (event: React.FormEvent) => {
    handleFormSubmit(event).catch((err) => {
      setError(err.message);
    });
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      <Form onSubmit={wrapHandleFormSubmit} id="login-sign-in">
        <Tooltip title="case-sensitive">
          <TextField
            type="username"
            label="Email / Username"
            onChange={handleUsernameChange}
            value={username}
            autoFocus
          />
        </Tooltip>
        <TextField
          label="Password"
          onChange={handlePasswordChange}
          type="password"
          value={password}
        />
        <Button disabled={!username || !password || isLoading}>Sign In</Button>
      </Form>
      {error && <FormHelperText error>{error}</FormHelperText>}
      <Box display="flex" justifyContent="space-between">
        <Link to="/forgot-password">Forgot Password?</Link>
        <Link to="/sign-up">Sign up</Link>
      </Box>
    </>
  );
}
