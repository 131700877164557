import { Button, Link } from '@material-ui/core';
import React from 'react';

export interface LinkImageButtonProps {
  imageUrl: string;
  href: string;
  width?: string;
}

export function LinkImageButton({
  href,
  imageUrl,
  width,
}: LinkImageButtonProps) {
  return (
    <Link href={href}>
      <Button>
        <img src={imageUrl} width={width || ''} />
      </Button>
    </Link>
  );
}
