import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../components/styled';

const useStyles = makeStyles((theme) => ({
  centeredText: {
    marginTop: 15,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'left',
  },
}));

export function CreateAccount() {
  const classes = useStyles();

  const logout = () => {
    location.href = '/auth/logout';
  };

  return (
    <Box pt={1}>
      <Box maxWidth={'500px'} marginTop="24px">
        Please contact your administrator to request access to this service.
        <br />
        <br />
        If you are an administrator, and would like to create an account please{' '}
        <a href="https://info.jupiterone.com/contact-us">Contact Us!</a>
      </Box>
      <Divider orientation="horizontal" className={classes.centeredText} />
      <Typography className={classes.centeredText}>
        <Button data-testid={`no-access-cancel`} onClick={logout}>
          Cancel
        </Button>
      </Typography>
    </Box>
  );
}
