import { useEffect } from 'react';
import { useAccounts } from '~/src/hooks/useAccounts';

export const useAccountSelectorLogic = () => {
  const { accounts, loading, error, getAccounts } = useAccounts();

  useEffect(() => {
    getAccounts();
  }, []);

  if (!loading && accounts.length === 0) {
    // If no accounts move to welcome page
    document.location = `/welcome`;
  } else if (!loading && accounts.length === 1) {
    // If one account redirect to that account
    const { accountId } = accounts[0];
     document.location = `/account/${accountId}${window.location.search}`;
  }

  return {
    accounts,
    loading,
    error,
  };
};
