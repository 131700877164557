import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    width: '100%',
    padding: 0,
    borderColor: '#b2b2b2',
    "& [class*='MuiButton-label']": {
      "& [class*='MuiButton-startIcon']": {
        marginLeft: 10,
      },
      "& [class*='MuiTypography']": {
        flexGrow: 1,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
  },
  icon: {
    flexAlign: 'start',
    marginRight: 3,
  },
  label: {
    borderLeft: '1px solid #b2b2b2',
  },
}));

interface Props {
  icon?: any;
  label: string;
  onClick: VoidFunction;
}

export function SignInActionButton({ icon, label, onClick }: Props) {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      startIcon={<img className={classes.icon} src={icon} />}
      id="sign-in-with-google"
      className={classes.root}
      onClick={onClick}
    >
      <Typography className={classes.label}>{label}</Typography>
    </Button>
  );
}
