import { IamInvitation } from '@jupiterone/login-shared';
import { useState } from 'react';

export const useInvitations = () => {
  const [invitations, setInvitations] = useState([] as IamInvitation[]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [updated, setUpdated] = useState<{
    invitationId: string;
    status: string;
  }>();

  const getInvitations = async () => {
    const response = await fetch('/invitations', {
      method: 'GET',
      credentials: 'include',
    });

    if (response.status === 200) {
      const invitations = await response.json();
      setInvitations(invitations);
    } else {
      const error = await response.json();
      setError(error.message);
    }
    setLoading(false);
  };

  const updateInvitation = async (invitationId: string, status: string) => {
    const response = await fetch(`/invitations/${invitationId}`, {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify({
        status,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const update = await response.json();
      setUpdated(update);
    } else {
      const error = await response.json();
      setError(error.message);
    }
    setLoading(false);
  };

  return {
    getInvitations,
    updateInvitation,
    invitations,
    loading,
    error,
    updated,
  };
};
