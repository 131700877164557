import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import 'cross-fetch/polyfill';

const CLIENT_ID = window.defaultPublicClientId;
const USER_POOL_ID = window.defaultUserPoolId;

const userPool = new CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: CLIENT_ID,
});

export function useAwsApi() {
  return {
    loginWithCredentials: async (
      username: string,
      password: string,
    ): Promise<CognitoUserSession> => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      return loginUser({ cognitoUser, username, password });
    },
    changePassword: async (params: {
      username: string;
      oldPassword: string;
      newPassword: string;
    }) => {
      const { username, oldPassword, newPassword } = params;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      await loginUser({ cognitoUser, username, password: oldPassword });

      return new Promise((resolve, reject) => {
        cognitoUser.changePassword(oldPassword, newPassword, (err) => {
          if (err) {
            return reject(new Error(`Unable to change password`));
          }
          resolve('Password successfully changed');
        });
      });
    },
    forgotPassword: async (params: { username: string }) => {
      const { username } = params;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: resolve,
          onFailure: reject,
        });
      });
    },
    confirmForgotPassword: async (params: {
      username: string;
      newPassword: string;
      code: string;
    }) => {
      const { username, newPassword, code } = params;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(code, newPassword, {
          onFailure: reject,
          onSuccess: () => resolve(undefined), // onSuccess type is not the same as resolve
        });
      });
    },
    signUp: async (params: {
      username: string;
      email: string;
      password: string;
    }): Promise<ISignUpResult> => {
      const { username, password, email } = params;

      return new Promise((resolve, reject) => {
        userPool.signUp(
          username,
          password,
          [new CognitoUserAttribute({ Name: 'email', Value: email })],
          [new CognitoUserAttribute({ Name: 'email', Value: email })],
          (err, result) => {
            if (err) {
              return reject(err);
            }
            resolve(result!);
          },
        );
      });
    },
    confirmSignUp: async (params: { username: string; code: string }) => {
      const { username, code } = params;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, false, (err, result) => {
          if (err) {
            return reject(err);
          }
          resolve(result);
        });
      });
    },
  };
}

function loginUser(params: {
  cognitoUser: CognitoUser;
  username: string;
  password: string;
}) {
  const { cognitoUser, username, password } = params;

  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  return new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });
}
