import {
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Background from '../Background';
import Footer from '../Footer';
import Logo from '../Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    padding: 40,
    minWidth: 340,
    position: 'relative',
    background: theme.palette.common.white,
    maxHeight: 600,
    overflowY: 'auto',
  },
  largeText: {
    fontSize: 34,
    lineHeight: '24px',
    paddingBottom: 8,
  },
  largeCenteredText: {
    fontSize: 34,
    lineHeight: '24px',
    paddingBottom: 8,
    textAlign: 'center',
  },
  sectionContainer: {
    marginTop: 20,
  },
  sectionLeft: {
    width: 340,
    marginRight: 40,
  },
  sectionRight: {
    width: 340,
    marginLeft: 40,
  },
  message: {
    padding: 10,
    marginTop: 10,
    border: `1px solid ${theme.palette.warning.light}`,
    color: theme.palette.warning.dark,
    fontWeight: 100,
    textShadow: `0px 0px .1rem ${theme.palette.warning.dark}`,
  },
}));

interface PageProps {
  title: string;
  centerTitle?: boolean;
  children?: any;
  showRightColumn?: boolean;
  rightColumn?: any;
  message?: string | null;
}

export function Page({
  children,
  title,
  rightColumn,
  showRightColumn = true,
  centerTitle = false,
  message = null,
}: PageProps) {
  const classes = useStyles();

  return (
    <Background>
      <Logo />
      <Footer />
      <Container component="main" className={classes.root}>
        <Paper className={classes.paper} elevation={20}>
          <Typography
            className={
              centerTitle ? classes.largeCenteredText : classes.largeText
            }
          >
            {title}
          </Typography>
          {message && (
            <Paper className={classes.message} elevation={0}>
              {`💡 ${message}`}
            </Paper>
          )}
          {!rightColumn && children}
          {rightColumn && !showRightColumn && children}
          {rightColumn && showRightColumn && (
            <Grid container className={classes.sectionContainer}>
              <Grid item className={classes.sectionLeft}>
                {children}
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item className={classes.sectionRight}>
                {rightColumn}
              </Grid>
            </Grid>
          )}
        </Paper>
      </Container>
    </Background>
  );
}
