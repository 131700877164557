import { FormHelperText, LinearProgress } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAwsApi } from '~/src/hooks';
import { Page } from '../../components/Page';
import { Button, Form, Link, TextField } from '../../components/styled';

export function ForgotPasswordForm() {
  const [username, setUsername] = React.useState('');
  const [newPassword, setPassword] = React.useState('');
  const [confirmNewPassword, setConfirmPassword] = React.useState('');
  const [code, setCode] = React.useState('');
  const [displayCode, setDisplayCode] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [message, setInfo] = React.useState('');
  const { forgotPassword, confirmForgotPassword } = useAwsApi();
  const history = useHistory();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setError('');
    setInfo('');
  };
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    setError('');
    setInfo('');
  };
  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPassword(event.target.value);
    setError('');
    setInfo('');
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(event.target.value);

    if (event.target.value !== newPassword) {
      setError('New password does not match confirmation');
    } else {
      setError('');
    }
    setInfo('');
  };

  const handleForgotPassword = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    forgotPassword({ username })
      .then(() => {
        setInfo(`A confirmation code has been sent to your inbox.`);
        setError('');
        setDisplayCode(true);
        setLoading(false);
      })
      .catch((err) => {
        setError('Unable to reset password');
        setLoading(false);
        return;
      });
  };

  const handleConfirmForgotPassword = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    confirmForgotPassword({ username, newPassword, code })
      .then(() => {
        setInfo(`You password has been reset`);
        setError('');
        setLoading(false);

        // Redirect to login
        history.push('/');
      })
      .catch((err) => {
        setError('Unable to reset password');
        setLoading(false);
        return;
      });
  };

  return (
    <Page
      title="Forgot Password"
      showRightColumn={displayCode}
      rightColumn={
        <>
          <TextField
            label="Code"
            onChange={handleCodeChange}
            value={code}
            autoComplete="new-password"
            autoFocus
          />
          <TextField
            label="New Password"
            onChange={handleNewPasswordChange}
            value={newPassword}
            autoComplete="new-password"
            type="password"
          />
          <TextField
            label="Confirm New Password"
            onChange={handleConfirmPasswordChange}
            value={confirmNewPassword}
            autoComplete="new-password"
            type="password"
          />
          <Button
            disabled={
              !confirmNewPassword ||
              !newPassword ||
              newPassword !== confirmNewPassword ||
              isLoading
            }
            onClick={handleConfirmForgotPassword}
          >
            Reset password
          </Button>
        </>
      }
    >
      {isLoading && <LinearProgress />}
      {message && <FormHelperText>{message}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
      <Form onSubmit={handleForgotPassword}>
        <TextField
          label="Email"
          onChange={handleUsernameChange}
          value={username}
          autoFocus
        />
        <Button disabled={!username || isLoading || displayCode}>
          Forgot password
        </Button>
      </Form>
      <Link to="/">Cancel</Link>
    </Page>
  );
}
