import React from 'react';
import { useCookies } from 'react-cookie';

export function Logout() {
  const [, , removeCookie] = useCookies(['j1-session']);
  async function doLogout(): Promise<void> {
    try {
      removeCookie('j1-session');
    } catch (err) {
      // TODO Sentry
    }
  }

  React.useEffect(() => {
    doLogout()
      .then(() => {
        document.location.replace('/');
      })
      .catch(() => {
        document.location.replace('/');
      });
  }, []);

  return <div>Logging out</div>;
}
