import { makeStyles } from '@material-ui/core';
import React from 'react';

export interface BackgroundProps {
  children: any;
}

export default function Background({ children }: BackgroundProps) {
  //Needed to put makeStyles in the render function
  const classes = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      height: '100vh',
      backgroundSize: '300% 300%',
      backgroundImage: `linear-gradient(-35deg,
          #041e42 0%,
          #043042 50%,
          #041e42 100%
        )`,
      overflow: 'hidden',
      position: 'relative',
    },
    spark1: {
      backgroundColor: '#64FFDA',
      position: 'absolute',
      width: '50%',
      height: '50%',
      filter: 'blur(300px)',
      transformOrigin: '10% -10%',
      left: '0%',
      top: '90%',
      borderRadius: '50%',
      opacity: 0.5,
    },
    spark2: {
      backgroundColor: '#64FFDA',
      position: 'absolute',
      width: '50%',
      height: '50%',
      filter: 'blur(300px)',
      transformOrigin: '90% 90%',
      left: '45%',
      top: '15%',
      borderRadius: '50%',
      opacity: 0.5,
    },
  }))();

  return (
    <div className={classes.root}>
      <div className={classes.spark1} />
      <div className={classes.spark2} />
      {children}
    </div>
  );
}
