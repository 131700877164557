import {
  Box,
  FormHelperText,
  LinearProgress,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { cognitoSessionToTokenSession } from '~/src/utils/cognitoUtils';
import { Page } from '../../components/Page';
import { Button, Form, Link, TextField } from '../../components/styled';
import { useAwsApi, useLoginApi } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 12,
    lineHeight: '16px',
  },
}));

export function SignUpForm() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [code, setCode] = React.useState('');
  const [message, setInfo] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [codeMessage, setInfo2] = React.useState('');
  const [displayCode, setDisplayCode] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { setSession } = useLoginApi();
  const { signUp, confirmSignUp, loginWithCredentials } = useAwsApi();
  const classes = useStyles();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError('');
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(event.target.value);

    if (event.target.value !== password) {
      setError('Password does not match confirmation');
    } else {
      setError('');
    }
    setInfo('');
  };

  const handelCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    setError('');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError('');
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    const usernameStr = `CognitoUserPool_${uuid()}`;

    // setUsername is not changing "username" before it is used in this function call.
    // must rely on the "usernameStr" for usage of usage of "username" in this func.
    setUsername(usernameStr);

    try {
      const result = await signUp({ username: usernameStr, email, password });
      setDisplayCode(!result.userConfirmed);
      setInfo2('Check email for code.');
    } catch (err) {
      if (err.name === 'InvalidPasswordException') {
        setError(err.message);
      } else {
        setError('Unable to sign-up');
      }
      setLoading(false);
    }
  };

  const wrapFormEvent = (func: (event: React.FormEvent) => Promise<void>) => {
    return (event: React.FormEvent) => {
      func(event).catch((err) => {
        setError(err.message);
      });
    };
  };

  const handleCode = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      await confirmSignUp({ username, code });

      const session = await loginWithCredentials(email, password);

      const tokenSession = cognitoSessionToTokenSession(session);

      await setSession({
        session: tokenSession,
      });

      //change to app-redirect
      document.location.assign(`/welcome${window.location.search}`);
    } catch (err) {
      setError(err.message ?? 'Unable to sign-up');
    }

    setLoading(false);
  };

  const signupDisabled =
    !email ||
    !password ||
    isLoading ||
    displayCode || // Confirmation code screen is displayed
    !confirmPassword ||
    password !== confirmPassword; // password does not match confirmation pwd

  return (
    <Page
      title="Sign Up"
      showRightColumn={displayCode}
      rightColumn={
        <>
          {codeMessage && <FormHelperText>{codeMessage}</FormHelperText>}
          <TextField label="Code" onChange={handelCodeChange} value={code} />
          <Button disabled={!code} onClick={wrapFormEvent(handleCode)}>
            Submit code
          </Button>
        </>
      }
    >
      {isLoading && <LinearProgress />}
      {error && <FormHelperText error>{error}</FormHelperText>}
      {message && <FormHelperText>{message}</FormHelperText>}
      <Form onSubmit={wrapFormEvent(handleFormSubmit)} id="login-sign-up">
        <Tooltip title="Email is case-sensitive">
          <TextField
            label="Email"
            onChange={handleEmailChange}
            value={email}
            autoFocus
          />
        </Tooltip>
        <TextField
          label="Password"
          onChange={handlePasswordChange}
          value={password}
          type="password"
        />
        <TextField
          label="Confirm Password"
          onChange={handleConfirmPasswordChange}
          value={confirmPassword}
          type="password"
        />
        <Box pt={3}>
          <Typography className={classes.text}>
            Password must be 8 characters long and contain:
            <ul>
              <li>An uppercase character</li>
              <li>A lowercase character</li>
              <li>A numeric character</li>
              <li>A special character</li>
            </ul>
          </Typography>
        </Box>
        <Button disabled={signupDisabled}>Sign up</Button>
      </Form>
      <Link to="/">Cancel</Link>
    </Page>
  );
}
